// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-associate-com-js": () => import("./../../../src/pages/projects/associate-com.js" /* webpackChunkName: "component---src-pages-projects-associate-com-js" */),
  "component---src-pages-projects-avnt-js": () => import("./../../../src/pages/projects/avnt.js" /* webpackChunkName: "component---src-pages-projects-avnt-js" */),
  "component---src-pages-projects-blitz-js": () => import("./../../../src/pages/projects/blitz.js" /* webpackChunkName: "component---src-pages-projects-blitz-js" */),
  "component---src-pages-projects-sircles-js": () => import("./../../../src/pages/projects/sircles.js" /* webpackChunkName: "component---src-pages-projects-sircles-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-user-experience-design-js": () => import("./../../../src/pages/services/user-experience-design.js" /* webpackChunkName: "component---src-pages-services-user-experience-design-js" */),
  "component---src-pages-services-web-and-mobile-development-js": () => import("./../../../src/pages/services/web-and-mobile-development.js" /* webpackChunkName: "component---src-pages-services-web-and-mobile-development-js" */),
  "component---src-pages-start-a-project-js": () => import("./../../../src/pages/start-a-project.js" /* webpackChunkName: "component---src-pages-start-a-project-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

